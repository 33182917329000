.Pagination {
  margin: clamp(48px, calc(64vw / 7.68), 64px) auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  div,
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-variant-numeric: lining-nums proportional-nums;
    width: 44px;
    height: 44px;
    border-radius: 50%;
  }
  div:not(.arrow) {
    width: 24px;
  }
  div.arrow {
    opacity: 0.62;
  }
  .arrow {
    svg {
      z-index: 1;
      position: relative;
    }
    &::before {
      z-index: 0;
    }
    &:first-child {
      margin-right: 2px;
    }
    &:last-child {
      margin-left: 2px;
    }
    background: var(--primary-300, #fff6f9);
  }
  a {
    position: relative;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: inherit;
      background: var(--primary-400, #ffd7e4);
      z-index: -1;
      transform: scale(0);
      transition: transform 0.3s var(--easing);
    }
    &:hover,
    &[aria-current='page'] {
      &::before {
        transform: scale(1);
      }
    }
  }
}
